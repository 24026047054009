// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/lazyload.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
import AOS from 'aos';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Scroll to section
   */
  $('a.scroll-link').click(function (e) {
    e.preventDefault();
    var id = $(this).attr('href');
    $('body,html').animate(
      {
        scrollTop: $(id).offset().top - 20,
      },
      750
    );
  });
  /**
   * Init AOS
   */
  $(function () {
    AOS.init({
      duration: 1200,
    });
    onElementHeightChange(document.body, function () {
      AOS.refresh();
    });
  });

  function onElementHeightChange(elm, callback) {
    var lastHeight = elm.clientHeight;
    var newHeight;

    (function run() {
      newHeight = elm.clientHeight;
      if (lastHeight !== newHeight) callback();
      lastHeight = newHeight;

      if (elm.onElementHeightChangeTimer) {
        clearTimeout(elm.onElementHeightChangeTimer);
      }

      elm.onElementHeightChangeTimer = setTimeout(run, 200);
    })();
  }
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item, .woocommerce-product-gallery__image')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */

  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.5,
  });

  var $elm1 = $('.float-1');
  var $elm2 = $('.float-2');
  var $elm3 = $('.float-3');
  function runForward(v) {
    // clone the array (before "animate()" modifies it), and reverse it
    var reversed = JSON.parse(JSON.stringify(v)).reverse();
    $(v[0]).animate(v[1], {
      duration: 2200,
      step: function (val) {
        $elm1.css('transform', `translate(139px, ${val}px)`);
      },
      done: function () {
        runForward(reversed);
      },
    });
  }
  function runForward2(s) {
    // clone the array (before "animate()" modifies it), and reverse it
    var reversed = JSON.parse(JSON.stringify(s)).reverse();
    $(s[0]).animate(s[1], {
      duration: 2400,
      step: function (val) {
        $elm2.css('transform', `translate(315px, ${val}px)`);
      },
      done: function () {
        runForward2(reversed);
      },
    });
  }
  function runForward3(w) {
    // clone the array (before "animate()" modifies it), and reverse it
    var reversed = JSON.parse(JSON.stringify(w)).reverse();
    $(w[0]).animate(w[1], {
      duration: 2400,
      step: function (val) {
        $elm3.css('transform', `translate(305px, ${val}px)`);
      },
      done: function () {
        runForward3(reversed);
      },
    });
  }
  runForward([{ y: 1121 }, { y: 1111 }]);
  runForward2([{ y: 1305 }, { y: 1315 }]);
  runForward3([{ y: 1024 }, { y: 1034 }]);

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */

  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  $('.btn__search').on('click', function () {
    $('.head-search').toggle();
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  /**
   * Tab Slider
   */
  $('.tab-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider-nav',
    speed: 1,
    arrows: false,
    fade: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          speed: 300,
        },
      },
    ],
  });
  $('.slider-nav').slick({
    slidesToShow: 5,
    asNavFor: '.tab-slider',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  });
});

/**
 * Scripts which runs after all elements load
 */
// jarallaxElement();
$(window).on('load', function () {
  // jQuery code goes here
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  // window.setTimeout(function () {
  //   $('.jarallax').jarallax({
  //     speed: 0.5,
  //   });
  // }, 400);
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
// var hW = $('.arrow-container').height();
// var hH = $('.section-history').height();

$(window).on('scroll', function () {
  $('.arrow-svg').css('top', $(this).scrollTop() / 6.5 + 'px');
  // console.log(hH);
  // console.log(hW);
});

function goToByScroll(id) {
  // Reove "link" from the ID
  id = id.replace('link', '');
  // Scroll
  $('html,body').animate(
    {
      scrollTop: $('#' + id).offset().top - 50,
    },
    'slow'
  );
}

$('.arrow-container > p > a').click(function (e) {
  // Prevent a page reload when a link is pressed
  e.preventDefault();
  // Call the scroll function
  goToByScroll($(this).attr('id'));
});
